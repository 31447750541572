.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1a3732;
  color: #fff;
  padding: 15px;
  font-size: 14px;
  z-index: 1000;
}

.cookie-message {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.cookie-buttons button {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #E7F21F;
  border: none;
  font-weight: 600;
  cursor: pointer;
  width: 120px;
  border-radius: 999px;
  color: #1a3732;
  transition: opacity 0.3s ease-in-out;
}

.cookie-buttons button:hover {
  opacity: 0.9;
}